import { PlayerId, UserToken } from '../../state/state';
import { useState } from 'react';

const SAFEWXRDS_TOKEN = "safewxrdsToken"

export const getToken = () => {
  const tokenString = sessionStorage.getItem(SAFEWXRDS_TOKEN);

  if (tokenString == null) {
    return undefined;
  }

  const userToken = JSON.parse(tokenString) as UserToken;

  return userToken || {};
};

export function useToken() {
  const [tokenInfo, setTokenState] = useState(getToken());

  const setToken = (userToken: UserToken | undefined) => {
    if (userToken == null) {
      sessionStorage.removeItem(SAFEWXRDS_TOKEN);
      setTokenState(undefined);
    } else {
      sessionStorage.setItem(SAFEWXRDS_TOKEN, JSON.stringify(userToken));
      setTokenState(userToken);
    }
  };

  return {
    setToken,
    token: tokenInfo?.token,
    userId: tokenInfo?.userId as PlayerId | undefined
  }
}