import { ServerComment } from "../../state/server/serverState";
import { BASE_URL, PlayerId, LoginCredentials, CommentId } from "../../state/state";
import { SAT } from "./SeverAppTransformers";

const API_URL = `${BASE_URL}/api`

export async function getPlayer(playerId: PlayerId) {
  const url = `${API_URL}/players/${playerId}`;

  const response = await fetch(url);
  const players = await response.json();

  if (players.length === 0) {
    console.warn(`Could not get user with id: ${playerId}`)
    return undefined;
  }

  const player = players[0];

  return player != null ? SAT.transformPlayer(player) : undefined;
}

export async function searchCommentsAboutPlayer(query: string) {
  const url = `${API_URL}/comments/commentsAboutPlayer/${query}`;

  const comments = await fetch(url).then(response => response.json() as any as ServerComment[])

  return comments.map(SAT.transformComment)
}

export async function searchCommentsByPlayer(query: string) {
  const url = `${API_URL}/comments/commentsByPlayer/${query}`;

  const comments = await fetch(url).then(response => response.json() as any as ServerComment[])

  return comments.map(SAT.transformComment)
}


export async function getPlayerFromFlId(id: string) {
  const url = `${API_URL}/players/fromFlId/${id}`

  const response = await fetch(url);

  const player = await response.json();
  return SAT.transformPlayer(player);
}

export async function getPlayerFromFLUsername(username: string) {
  const url = `${API_URL}/players/fromFlUsername/${username}`

  const response = await fetch(url);
  const maybePlayer = await response.json();

  return SAT.transformPlayer(maybePlayer);
}

export async function getFetlifeUsers() {
  const url = `${API_URL}/fetlife`

  const response = await fetch(url);
  const fetlifeUsers = await response.json();

  return SAT.transformFetlifes(fetlifeUsers);
}

export async function loginUser(credentials: LoginCredentials) {
  const url = `${API_URL}/login`

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })

  if (response.status === 500) {
    return undefined;
  } else {
    return await response.json();
  }
}

export async function getFetlifeInfoFromPlayerId(playerId: PlayerId) {
  const url = `${API_URL}/fetlife/${playerId}`

  const response = await fetch(url);
  const fetlifeUsers = await response.json();

  return SAT.transformFetlife(fetlifeUsers)
}

export function createUser({ username, id, password
}: { username: string; id: string; password: string;}) {
  const url = `${API_URL}/players/create`;

  return fetch(url, {
    method: "POST",
    body: JSON.stringify({
      fetlife_id: id,
      fetlife_username: username,
      password,
    }),
    headers: {
      'Content-Type': 'application/json'
    },
  }).catch(err => console.error(err)).then((d) => console.log("DID IT!!!"));

}


 export function createNewComment(text: string, isAbout: string, createdBy: string) {
  const url = `${API_URL}/comments/create`;

  return fetch(url, {
    method: "POST",
    body: JSON.stringify({
      created_by: createdBy,
      is_about: isAbout,
      comment: text,
    }),
    headers: {
      'Content-Type': 'application/json'
    },
  }).catch(err => console.error(err));
}


export function deleteComment(commentId: CommentId) {
  const url = `${API_URL}/comments/delete/${commentId}`;

  return fetch(url, {
    method: "DELETE",
  }).catch(err => {
    console.error(`Could not delete comment with ID: ${commentId}\n Server Error: ${err}`);
  })
}

export function flagComment(commentId: CommentId) {
  const url = `${API_URL}/comments/flag/${commentId}`;

  return fetch(url, {
    method: "POST",
    body: JSON.stringify({
      comment_id: commentId,
    }),
  }).catch(err => {
    console.error(`Could not flag comment with ID: ${commentId}\n Server Error: ${err}`);
  })
}

export function unflagComment(commentId: CommentId) {
  const url = `${API_URL}/comments/unflag/${commentId}`;

  return fetch(url, {
    method: "POST",
    body: JSON.stringify({
      comment_id: commentId,
    }),
  }).catch(err => {
    console.error(`Could not unflag comment with ID: ${commentId}\n Server Error: ${err}`);
  })
}