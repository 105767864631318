import { PlayerId } from "./state";

export const MercyRoutes = {
  HOME: "/",
  LOGIN: "/login",
  PLAYER_PAGE: "/player/:player_id",
  ADMIN: "/admin",
  LEAVE_COMMENT: "/leaveComment/:player_id",
  CREATE_ACCOUNT: "/createAccount",
  ABOUT: "/about",
  RESET_PASSWORD: "resetPassword",
}

export function getPlayerPageRouteForPlayer(playerId: PlayerId) {
  return MercyRoutes.PLAYER_PAGE.replace(":player_id", playerId);
}