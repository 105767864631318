import React, { useCallback } from 'react';
import './css/App.sass';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { LoginPage } from "./pages/login/LoginPage";
import { MercyRoutes } from "./state/routes";
import { useNavigate } from 'react-router';
import { CreateAccountPage } from './pages/login/CreateAccountPage';
import { ResetPasswordPage } from './pages/login/ResetPasswordPage';
import { AboutUsPage } from './pages/about/AboutUsPage';
import "./css/LoginPage.sass";
// import logo from './resources/images/logo.png';

export function UnauthedApp({ setToken, dispatch}) {
  const navigate = useNavigate();

  const handleClickAboutUs = useCallback(() => {
    navigate(MercyRoutes.ABOUT)
  }, [navigate]);

  return (
      <div className="login">
        <div className="login-widget">
          <div className="header">
            {/* <img className="logo" src={logo} /> */}
            <div className="title">SafeWxrds</div>
            <div className="subtitle">Your secrets are safe here.</div>
          </div>
          <Routes>
            <Route path="/" element={
              <LoginPage setToken={setToken} dispatch={dispatch}/>
            }>
              <Route path={"*"} element={
                <LoginPage setToken={setToken} dispatch={dispatch}/>
              }/>
            </Route>
            <Route path={MercyRoutes.ABOUT} element={
              <AboutUsPage />
            }/>
          </Routes>
        </div>
        <div className="about-us" onClick={handleClickAboutUs}>About Us</div>
      </div>
  );
}
