import { findKey } from "lodash";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { MercyContext } from "../../state/MercyContext";
import { PlayerId } from "../../state/state";
import { MercyGlobalState } from "../../state/mercyReducer";


function getFlIdForUsername(state: MercyGlobalState, username: string) {
  return findKey(state.fetlifeUsers, (v: string) => v === username)
}



export function useGetIdFromPlayerUrl(): PlayerId {
  const location = useLocation();
  const urlParts = location.pathname.split('/');
  const { state } = useContext(MercyContext);

  switch (urlParts[1]) {
    case "player":
      return urlParts[2] as PlayerId;
    case "leaveComment":
      return (getFlIdForUsername(state, urlParts[2]) ?? urlParts[2]) as PlayerId;
    default:
      return state.user.playerId as PlayerId;
  }
}