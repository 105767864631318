import React, { useContext, useCallback, useState } from "react";
import "../css/AppHeader.sass";
import { Input } from "antd";
import { useNavigateToHome } from "../utils/hooks/navigation/useNavigateToHome";
import { MercyContext } from "../state/MercyContext";
import { useNavigateToPlayerPage } from "../utils/hooks/navigation/useNavigateToPlayerPage";

import logo from '../resources/images/logo.png';
import { AppHeaderRight } from "./AppHeaderRight";
import { getPlayerFromFLUsername } from "../utils/server/serverAPI";
const { Search } = Input;

export function AppHeader({ clearToken }) {
  const [text, setText] = useState("");
  const { dispatch } = useContext(MercyContext);
  const navigateToPlayerPage = useNavigateToPlayerPage();
  const navigateToHome = useNavigateToHome();

  const handleChange = useCallback((evt) => {
    const text = (evt.target as any).value;
    setText(text);
  }, [setText])

  const handlePressEnter = useCallback((evt) => {
    async function doCallback() {
      const text = (evt.target as any).value;
      if (evt.key != "Enter") {
        return;
      }

      const player = await getPlayerFromFLUsername(text);
      navigateToPlayerPage(player.playerId);
    }
    doCallback();
  }, [navigateToPlayerPage, getPlayerFromFLUsername])


  return (
    <>
    <div className="app-header">
      <div className="logo" onClick={navigateToHome}>
        <img src={logo} />
      </div>
      <Search
        style={{ maxWidth: 400 }}
        className="search"
        onChange={handleChange}
        placeholder="Search SafeWxrds Users"
        onKeyDown={handlePressEnter}
      />
      <AppHeaderRight clearToken={clearToken} />
    </div>
    </>
  )
}