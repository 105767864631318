import React, { useContext, useCallback } from "react";
import { useNavigateToUserPage } from "../utils/hooks/navigation/useNavigateToUserPage";
import { useNavigateToLoginPage } from "../utils/hooks/navigation/useNavigateToLoginPage";
import { useNavigateToHome } from "../utils/hooks/navigation/useNavigateToHome";
import { useGetUserFetlifeUsername } from "../utils/hooks/state-getters/useGetUserFetlifeUsername";
import { MercyContext } from "../state/MercyContext";
import { CaretDownOutlined, DownCircleOutlined, DownCircleTwoTone } from "@ant-design/icons";
import { Popover } from "antd";

export function AppHeaderRight({ clearToken }) {
  const { dispatch } = useContext(MercyContext);
  const navigateToHome = useNavigateToHome();
  const navigateToLoginPage = useNavigateToLoginPage();
  const navigateToUserPage = useNavigateToUserPage();

  const username = useGetUserFetlifeUsername();

  const handleLogout = useCallback(() => {
    clearToken();
    dispatch({ type: "RESET_USER" })
    navigateToHome();
  }, [dispatch, clearToken, navigateToLoginPage]);

  return (
    <div className="app-header-right">

      <div onClick={navigateToUserPage}>{username}</div>
      <Popover
        content={<div onClick={handleLogout}>Logout</div>}
        overlayStyle={{ cursor: "pointer"}}
        placement="bottomRight"
        trigger="click"
      >
        <DownCircleOutlined />
      </Popover>
    </div>
  )
}


    // <Popover content={content} title="Title" trigger="click">
    //   <Button>Click me</Button>
    // </Popover>