import React, { useState, useCallback } from 'react';
import { loginUser, getPlayerFromFLUsername } from '../../utils/server/serverAPI';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'antd';

import "../../css/LoginPage.sass";

async function initializeUser(flUsername: string, dispatch) {
  const user = await getPlayerFromFLUsername(flUsername);
  dispatch({ type: "SET_USER", payload: user})
}

export function LoginPage({ setToken, dispatch }) {
  const [isInvalid, setIsInvalid] = useState(false);

  const onFinish = useCallback(({ username, password }) => {
    console.log("USERNAME: ", username)
    console.log("PASSWORD: ", password)
    if (username == null || password == null) {
      setIsInvalid(true);
      return;
    }

    loginUser({
      username: username,
      password
    }).then(response => {
      if (response == null || response.token == null) {
        setIsInvalid(true);
      } else {
        setToken(response);
        initializeUser(username, dispatch);
      }
    })
  }, [setIsInvalid, loginUser, setToken, initializeUser]);

  return (
      <Form
        name="login-form"
        layout="vertical"
        style={{ width: 300 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          label="Fetlife Username"
          name="username"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item label={null}>
          <Button htmlType="submit" className="signInButton">
            Sign in
          </Button>
        </Form.Item>
      </Form>
    )
  }
