import React from 'react';
import { Card, Popover } from "antd";
import { Comment } from "../../../state/state";
import "../../../css/CommentCard.sass";
import { formatAsLocalTime } from "../../../utils/formatAsLocalTime";
import { PlayerTag } from "../../../common/PlayerTag";
import { CommentCardMoreOptions } from './CommentCardMoreOptions';
import { WarningFilled } from '@ant-design/icons';

function Attribution({ comment }: { comment: Comment }) {
  const { writtenBy, createdAt, isAbout } = comment;

  return (
    <div className="footer">
      Written by <PlayerTag playerId={writtenBy} /> about <PlayerTag playerId={isAbout} /> on <b>{formatAsLocalTime(createdAt)}</b>
    </div>
  )
}

export function CommentCard({ comment, reloadComments }: { comment: Comment, reloadComments: any }) {
  return (
    <Card className="comment-card">
      <div className="comment-card-header">
        <Attribution comment={comment} />
        <CommentCardMoreOptions comment={comment} reloadComments={reloadComments} />
      </div>
      <div className="comment">
        <CommentWarningIcon comment={comment}/>
        {comment.comment}
      </div>
    </Card>
  );
}

export function CommentWarningIcon({ comment }: { comment: Comment }) {
  if (comment.flaggedAt == null) {
    return null;
  }

  const popoverContent = <div className="comment-flag-popover-content"><PlayerTag playerId={comment.isAbout} /> has flagged part of this comment as inaccurate. For further clarification, please contact the parties involved.</div>

  return (
    <Popover
      content={popoverContent}
      placement='topLeft'
      color={"#fa5711"}
      overlayStyle={{
        maxWidth: 430
      }}
      arrow={{ pointAtCenter: true }}
    >
      <WarningFilled className="comment-flag-icon" />
    </Popover>
  )
}