import React from "react";
import { Button } from "antd";
import { useNavigate } from 'react-router-dom';
import { useGetIdFromPlayerUrl } from "../../utils/hooks/useGetIdFromPlayerUrl";
import { MercyRoutes } from "../../state/routes";

export function LeaveCommentButton() {
  const navigate = useNavigate();
  const playerId = useGetIdFromPlayerUrl();

  const handleClick = () => {
    navigate(MercyRoutes.LEAVE_COMMENT.replace(":player_id", playerId));
  }

  return (
    <Button className="leave-comment-button" onClick={handleClick}>
      Leave a comment
    </Button>
  )
}