import React, { useEffect, useState } from 'react';
import { CommentCard } from "./CommentCard";
import { Comment } from "../../../state/state";
import "../../../css/CommentsSection.sass";
import { useGetIdFromPlayerUrl } from '../../../utils/hooks/useGetIdFromPlayerUrl';


export function CommentsSection({ loadComments }: { loadComments: any; }) {
  const [comments, setComments] = useState<Comment[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const playerId = useGetIdFromPlayerUrl();

  useEffect(() => {
    setIsLoading(true);
    loadComments(playerId).then(comments => {
      setComments(comments);
      setIsLoading(false);
    })
  }, [playerId, setComments, setIsLoading, loadComments]);

  if (comments.length == 0 && isLoading == false) {
    return <div className="comments-section-no-comments"><div>No comments yet. :( </div><div>Be the first to leave a comment!</div></div>
  }

  return (
    <div className={"comments-section"}>{comments.map((comment, i) => (
      <CommentCard key={i} comment={comment} reloadComments={loadComments} />
    ))}</div>
  )
}