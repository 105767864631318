import React, { useCallback, useEffect, useState } from 'react';
import { Select } from 'antd';
import { Player } from '../../state/state';
import { LeaveCommentButton } from "./LeaveCommentButton";
import { getPlayer, searchCommentsAboutPlayer, searchCommentsByPlayer } from "../../utils/server/serverAPI";
import "../../css/PlayerPage.sass";
import { useGetIdFromPlayerUrl } from '../../utils/hooks/useGetIdFromPlayerUrl';
import { CommentsSection } from './comments-section/CommentsSection';

const enum PlayerPageTabs {
  ABOUT = "ABOUT",
  BY = "BY",
}

interface PlayerPageState {
  player?: Player;
  activeTab: PlayerPageTabs;
}

export function PlayerPage() {
  const [state, setState] = useState<PlayerPageState>({
    player: undefined,
    activeTab: PlayerPageTabs.ABOUT,
  });
  const [isLoading, setIsLoading] = useState(true);

  const playerId = useGetIdFromPlayerUrl()

  useEffect(() => {
    setIsLoading(true);
    getPlayer(playerId).then(player => {
      setState(state => ({
        ...state,
        player,
      }))
      setIsLoading(false);
    });
  }, [getPlayer, playerId, setState, setIsLoading])

  const handleTabChange = useCallback((newTab) => {
    setState(state => ({
      ...state,
      activeTab: newTab
    }))
  }, [setState]);

  const username = state.player?.fetlife.username ?? "";
  const options = [
    { value: PlayerPageTabs.ABOUT, label: `Comments about ${username}`  },
    { value: PlayerPageTabs.BY, label: `Comments by ${username}` },
  ];

  if (state.player == null && isLoading === false) {
    return <div className="error-page">Sorry, that person has not yet signed up for SafeWxrds. Please search for another user.</div>;
  }

  const loadComments = state.activeTab === PlayerPageTabs.ABOUT ? searchCommentsAboutPlayer : searchCommentsByPlayer;

  return (
    <div className='player-page'>
      <div className="player-page-header">
        <div className="user">{username}</div>
        <div className="player-page-selects">
          <Select
            className="about-select"
            value={state.activeTab}
            onChange={handleTabChange}
            options={options}
          />
          <LeaveCommentButton />
        </div>
      </div>
      <CommentsSection
        loadComments={loadComments}
      />
    </div>
  );
}
