import React, { useContext, useCallback, useState } from "react";
import { Input, Button } from "antd";
import { MercyContext } from "../../state/MercyContext";
import { useNavigateToPlayerPage } from "../../utils/hooks/navigation/useNavigateToPlayerPage";
import { PlayerTag } from "../../common/PlayerTag";
import { createNewComment } from "../../utils/server/serverAPI";
import "../../css/LeaveCommentPage.sass";
import { useGetIdFromPlayerUrl } from "../../utils/hooks/useGetIdFromPlayerUrl";

const { TextArea } = Input;

export function LeaveCommentPage() {
  const { state } = useContext(MercyContext);
  const [text, setText] = useState("");
  const playerId = useGetIdFromPlayerUrl();
  const navigateToPlayerPage = useNavigateToPlayerPage();

  const handleChange = useCallback((evt) => {
    setText((evt.target as any).value);
  }, [setText])

  const handleSubmit = useCallback(() => {
    createNewComment(text, playerId, state.user.playerId);
    // TODO - error handling of bad comment
    navigateToPlayerPage(playerId);
  }, [text, playerId, state])

  return (
    <div className="leave-comment-page">
      <div className="leave-comment-header">Leave a comment about <PlayerTag playerId={playerId} /></div>
      <TextArea
        className="leave-comment-text-area"
        value={text}
        onChange={handleChange}
      />
      <Button className="submit-button" onClick={handleSubmit} >
        Submit
      </Button>
    </div>
  )
}